import React, { useState, useEffect } from 'react';
import './style.scss';
import { ORDER, ORDER_PARTNER } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { DATETIME_JP_FORMAT, SCREEN_NAME } from 'helpers/constants';
import { Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';

const defaulfUpload = {
  'managementFileReportFirst': '',
  'managementFileReportSecond': ''
};
const listParam = ['comment', 'status', 'name', 'key', 'timestamp', 'url', 'numberOfMaterials'];
const DetailTableUploadReportUploadInfo = ({ data, onReload, title = '報告書', history, documentSetting = [], screen = '' }) => {
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'report_upload_info';
  const [dataOrder, setDataOrder] = useState(data);
  const [fileName, setFileName] = useState(defaulfUpload);
  const [contentFile, setContentFile] = useState(defaulfUpload);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setDataOrder(data);
  }, [data]);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const handleDataPick = () => {
    let pick = documentSetting.map(e => {
      if (dataOrder[e.timestamp]) {
        dataOrder[e.timestamp] = _.pick(dataOrder[e.timestamp], listParam);
      }
      return e.timestamp;
    });
    pick = [
      ...pick,
      'screenName',
      'updateTitle',
      'companyName',
      'nameApplication',
    ];
    return pick;
  };
  const PICK_REPORT_UPLOAD_INFO = handleDataPick();
  const handleUpdate = () => {
    const body = _.pick({ ...dataOrder, screenName: screen || SCREEN_NAME.REPORT_UPLOAD_INFO, updateTitle: '報告書提出', companyName: localStorage.getItem('companyName'), nameApplication: '報告書' }, PICK_REPORT_UPLOAD_INFO);
    handleUpdateReport({
      body,
      orderId, organizationId
    });
  };

  const handleUpdateReport = async ({ body = {}, orderId, organizationId }) => {
    try {
      const data = await ORDER_PARTNER.CHANGE_ORDER_REPORT_DATE({ body, id_order: orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update Report success');
        onReload();
        return history.push(`/list?p=report_upload_info`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpload = async ({ nameType, numberOfMaterials }) => {
    const body = {
      content: contentFile?.[nameType],
      fileName: fileName?.[nameType],
      keyFile: nameType,
      screenName: screen || SCREEN_NAME.REPORT_UPLOAD_INFO,
      numberOfMaterials,
      updateTitle : 'アップロード',
      companyName: localStorage.getItem('companyName'),
      nameApplication: '報告書',
    };
    handleUploadFile({ body, orderId });
  };

  const handleUploadFile = async ({ body, orderId }) => {
    try {
      const data = await ORDER.UPLOAD_FILES_REPORT_MANAGEMENT({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Upload success');
        onReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChangeHandler = async ({ e, nameType }) => {
    const files = e?.target?.files[0];
    const name = e?.target?.files[0]?.name;
    setFileName({
      ...fileName,
      [nameType]: name
    });

    setContentFile({
      ...contentFile,
      [nameType]: await toBase64(files)
    });
  };

  const handleChangeStatus = ({ nameType, value }) => {
    setDataOrder({
      ...dataOrder,
      [nameType]: {
        ...dataOrder[nameType],
        status: value,
        timestamp: moment(new Date()).format(DATETIME_JP_FORMAT)
      }
    });
  };

  const handleChangeComment = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setDataOrder({
      ...dataOrder,
      [name]: {
        ...dataOrder[name],
        comment: value
      }
    });
  };

  const handleLinkWorker = () => {
    history.push(
      `/report-order?onBack=detail-report-list&orderId=${orderId}&organizationId=${organizationId}&menuName=workOnTheDay`
    );
  };

  return (
    <div className='upload-wrapper-management'>
      <div className='form-wrapper'>
        <div className='title-absolute' onClick={onClickShow}>
          報告書
          {isOpen ? (
            <i className='fas fa-arrow-circle-up ml-2'></i>
          ) : (
            <i className='fas fa-arrow-circle-down ml-2'></i>
          )}
        </div>
        <Collapse isOpen={isOpen}>

          <div className='table-upload'>

            <div className='row-table'>
              <div className='header-row'>
                <div className='item-header full'>
                  <p>Worker報告書</p>
                </div>
              </div>
              <div className='content-row'>
                <button
                  className='btn-modal'
                  onClick={() => handleLinkWorker()}
                >報告書確認
                </button>

              </div>
            </div>

            {documentSetting?.map((elm, index) => {
              return (
                <div key={index} className='row-table'>
                  <div className='header-row'>
                    <div className='item-header full'>
                      <p>{elm.numberOfMaterials}</p>
                    </div>
                  </div>
                  {elm.uploadFile ? (
                    <>
                      <div className='group-content-row'>
                        <div className='content-row'>
                          <label htmlFor={elm.timestamp} className='btn-modal'>
                            ファイル選択
                          </label>
                          <input
                            type='file'
                            name='file'
                            id={elm.timestamp}
                            className='d-none input-contract'
                            onChange={(e) => onChangeHandler({ e, nameType: elm.timestamp })}
                          />

                          <input
                            type='text' disabled
                            value={fileName?.[elm.timestamp] || dataOrder[elm.timestamp]?.name}
                            className={`input-modal ${elm?.isRequired && !(fileName?.[elm.timestamp] || dataOrder[elm.timestamp]?.name) && 'content__input--required'}`} />
                        </div>
                      </div>

                      <div className='group-content-row'>
                        <div className='content-row'>
                          <button
                            className='btn-modal'
                            id={elm.timestamp}
                            onClick={() => handleUpload({
                              nameType: elm.timestamp,
                              numberOfMaterials: elm.numberOfMaterials
                            })}
                          >アップロード
                          </button>
                          {elm.uploadFromAndTo ? (<button
                            className='btn-modal'
                            disabled={!dataOrder ?.[elm.timestamp]?.url}
                            onClick={() => window.open(`${dataOrder ?.[elm.timestamp]?.url}`)}
                          >ダウンロード</button>) : (<div className='empty-div'></div>)}
                        </div>
                      </div>
                      <div className='group-content-row'>
                        <div className='content-row'>
                          {
                            (_.isEmpty(dataOrder ?.[elm.timestamp]) || !dataOrder ?.[elm.timestamp]?.status) ?
                              <input
                                type='text'
                                onChange={handleChangeComment}
                                name={elm.timestamp}
                                value={dataOrder ?.[elm.timestamp]?.comment}
                                disabled
                                className='input-modal' /> : <div className='empty-div'></div>
                          }
                          <p className='text-time'>
                            {dataOrder ?.[elm.timestamp]?.timestamp}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='group-content-row'>
                        <div className='content-row'>
                          <button className='download-btn'>
                            <p>
                              {dataOrder?.[elm.timestamp]?.name}
                            </p>
                          </button>
                        </div>
                      </div>
                      <div className='group-content-row'>
                        <div className='content-row'>
                          <button
                            className='btn-modal'
                            disabled={!dataOrder ?.[elm.timestamp]?.url}
                            onClick={() => window.open(`${dataOrder ?.[elm.timestamp]?.url}`)}
                          >ダウンロード
                          </button>
                          <button
                            onClick={() => handleChangeStatus({ nameType: elm.timestamp, value: true })}
                            className={`btn-modal ${(dataOrder ?.[elm.timestamp]?.status || !_.has(dataOrder ?.[elm.timestamp], 'status')) ? '' : 'isCheck'}`}
                          >OK
                          </button>
                          <button
                            onClick={() => handleChangeStatus({ nameType: [elm.timestamp], value: false })}
                            className={`btn-modal ${(!dataOrder?.[elm.timestamp]?.status || !_.has(dataOrder ?.[elm.timestamp], 'status')) ? '' : 'isCheck'}`}
                          >NG
                          </button>
                        </div>
                      </div>
                      <div className='group-content-row'>
                        <div className='content-row'>
                          {
                            (_.isEmpty(dataOrder ?.[elm.timestamp]) || !dataOrder ?.[elm.timestamp]?.status) ?
                              <input
                                type='text'
                                onChange={handleChangeComment}
                                name={elm.timestamp}
                                value={dataOrder ?.[elm.timestamp]?.comment}
                                className='input-modal' /> : <div className='empty-div'></div>
                          }

                          <p className='text-time'>
                            {dataOrder ?.[elm.timestamp]?.timestamp}
                          </p>
                        </div>
                      </div>
                    </>

                  )}
                </div>
              );
            })}

            <div className='group-btn-update'>
              <button className='btn-modal'
                      onClick={() => handleUpdate()}
              >報告書提出
              </button>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default withRouter(DetailTableUploadReportUploadInfo);
