import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import _ from 'lodash';

const DetailData = ({ data, p = 'receive_info', link = true, title = '物件情報' }) => {
  return (
    <div className="detail-data-wrapper detail-data-center">
      {link && <div className="logout_btn">
        <Link to={`list?p=${p}`}>
          <span className="mypage_ab">戻る</span>
        </Link>
      </div>}
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute"> {title} </div>
        <table className="conditTab">
          <tbody>
            <tr>
              <th>管理コード</th>
              <td>{data?.managementCode}</td>
            </tr>
            <tr>
              <th width="200">訪問先名</th>
              <td> {data?.visitedCompanyName} </td>
              <th>物件名</th>
              <td>{data?.installationSiteName}</td>
            </tr>
            <tr>
              <th>訪問先担当者連絡先</th>
              <td> {data?.contactInformation} </td>
              <th width="200">訪問先担当者</th>
              <td> {data?.contactPersonInCharge} </td>
            </tr>
            <tr>
              <th>訪問先郵便番号</th>
              <td> {data?.visitedPostalCode} </td>
              <th>訪問担当者メール</th>
              <td> {data?.visitEmailAddress} </td>
            </tr>
            <tr>
              <th>訪問先住所</th>
              <td> { _.compact([1, 2, 3, 4, 5].map(e => { return data?.[`visitedBaseAddress${e}`] })).join('')} </td>
              <th>訪問先都道府県</th>
              <td> {data?.prefecturesVisited} </td>
            </tr>
            <tr>
              <th>訪問先建物名</th>
              <td> {data?.visitedBuildingName} </td>
              <th>作業予定箇所</th>
              <td> {data?.scheduledInstallationLocation} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailData;
