import React from 'react';
import { getNoOrder, getRoleNew } from '../../../../helpers/helpers';
import { Link } from 'react-router-dom';
import { ROLE } from '../../../../helpers/constants';
import moment               from 'moment';

const data = {
  header: [
    'CloudID',
    '企業名',
    '作成日時',
    '更新日時',
  ],
};

const User = ({ dataOrganizations = [], colors, activePage, isVerifyRole, checkKey, roleOrganization, dataNew = '' }) => {

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className="no-wrapper">
          <div>{getNoOrder({ index: index, limit: 10, activePage: activePage })}</div>
        </td>
        <td>
          {/* getRoleNew({idRole : localStorage.getItem('role')})  === ROLE.NEOMARS ?  item?.organizationId : dataNew?.organizationId */}
          { checkKey === 'management'
            ? item?.partnerId
            : checkKey === 'monitoring'
            ? item?.clientId
            : item?.organizationId }
        </td>
        <td>{getRoleNew({idRole : localStorage.getItem('role')})  === ROLE.NEOMARS ? item.companyName : item?.officialName}</td>
        <td>{moment(item?.createdAt).format('YYYY-MM-DD HH:mm')}</td>
        <td>{moment(item?.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
        <td className="button--center">
          <Link
            to={
              isVerifyRole && roleOrganization === ROLE.PARTNERGROUP && checkKey === 'management'
                ? `/users/register-user?organizationId=${item?.organizationId}&role=Management&partnerId=${item?.partnerId}`
                : isVerifyRole && roleOrganization === ROLE.PARTNERGROUP && checkKey === 'monitoring'
                ? `/users/register-user?organizationId=${item?.organizationId}&role=Monitoring&clientId=${item?.clientId}&typeRole=monitor`
                : `/users/register-user?organizationId=${item?.organizationId}&role=${checkKey === 'erp' ? 'ERP' : 'ManagementGroup'}`
            }
          >
            <div className="button__edit" style={{ width: 100 }}>
            登録
            </div>
          </Link>
        </td>
        <td className="button--center">
          <Link
            to={
              isVerifyRole && roleOrganization === ROLE.PARTNERGROUP
                ? `/organizations/edit-organization?sid=${
                    checkKey === 'management' ? item?.partnerId : item?.clientId
                  }&role=${checkKey}`
                : `/organizations/edit_modify_organization?sid=${item?.organizationId}`
            }
            className=""
          >
            <div className="button__edit">編集</div>
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <div className="table-list-wrapper table-responsive">
      <table className={`table table-striped small`}>
        <thead>
          <tr style={{ backgroundColor: `${colors}` }}>
            <th scope="col">No</th>
            {data.header.map((item, index) => (
              <th scope="col" key={index}>
                {(index == 0)? (checkKey === 'management')?'協力企業ID':'発注企業ID' : item}
              </th>
            ))}
            <th scope="col">利用者登録</th>
            <th scope="col">操作</th>
          </tr>
        </thead>
        <tbody>{dataOrganizations.map((listData, index) => renderItem(listData, index, activePage))}</tbody>
      </table>
    </div>
  );
};

export default User;
