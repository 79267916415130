import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import Footer from 'components/Footer';
import { pushToast } from '../../../components/Toast';
import { ORDER } from '../../../constants/endpoints';
import { HEADER_MENU, ROLE, SCREEN_NAME, STATUS_CONFIRM_PARTNER } from '../../../helpers/constants';
import { getRole } from '../../../helpers/helpers';
import 'assets/scss/page/DetailConditionInfo/style.scss';
import { Link, useHistory } from 'react-router-dom';
import DetailData from '../../../components/DetailCenter/DetailData';
import DetailDataList from '../../../components/DetailCenter/DetailDataList';
import DetailOrder from '../../../components/DetailCenter/DetailOrder';
import DetailListAllData from '../../../components/DetailCenter/DetailListAllData';
import ButtonDetail from '../../../components/ButtonDetail';
import _ from 'lodash';

import { compose } from 'redux';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';

const DetailConditionInfo = ({ t }) => {
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let back = (process.browser && new URL(window.location.href).searchParams.get('back')) || '';
  const [data, setData] = useState([]);
  const [namePartNer, setNamePartner] = useState('');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'condition_info';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const history = useHistory();
  const idProvider = localStorage.getItem('idProvide');

  const [listDetailScreen, setListDetailScreen] = useState([]);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'condition_info',
        orderId,
        organizationId
      });
      if (data) {
        // setListDetailScreen(data?.data?.detailScreen);
        setListDetailScreen(data?.data?.detailScreen?.concat({ name: 'documentSetting', sortNum: 99, status: true }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const mockData = {
    erpMattersToBeSent: '',
    workerResponseRequired: '',
    screenName: SCREEN_NAME.CONDITION_INFO,
  };

  const [role, setRole] = React.useState(null);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = React.useState(null);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [dataOrder2, setDataOrder2] = useState({});
  const [dataPartner, setDataPartner] = useState([]);
  const [dataPartnerCheck, setDataPartnerCheck] = useState([]);
  const [dataPartnerDetail, setDataPartnerDetail] = useState([]);
  const [dataPartnerConfirm, setDataPartnerConfirm] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [listPartnerAll, setListPartnerAll] = useState([]);
  const [dataCheck, setDataCheck] = useState([]);

  useEffect(() => {
    getPartnerListOr({ orderId: orderId });
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }

    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const getPartnerListOr = async ({ page = 0, limit = 10, name = '', orderId }) => {
    try {
      const data = await ORDER.GET_ALL_PARTNER({
        page: page,
        limit: limit,
        name: name,
        orderId: orderId,
        organizationId,
      });
      if (data) {
        const tmpArr = data?.data?.result?.map((item) => {
          return {
            partnerId: item.partnerId,
            partnerName: item.abbreviationName,
            fullName: `${item.partnerId} - ${item.name} - ${item.nameKana}`,
            distance: item.distance,
            abbreviationName: item?.abbreviationName,
            isChildCompany: item.isChildCompany,
            organizationId: item.organizationId,
            areaInOut: item?.areaInOut,
          };
        });
        setDataPartner(tmpArr);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'condition_info', organizationId });
      if (data) {
        setData(data?.data);
        // for (const partner of data?.data?.partners) {
        //   console.log(partner);
        //   if (partner.statusOrderCondition === STATUS_CONFIRM_PARTNER.confirmedEng || partner.statusOrderCondition === STATUS_CONFIRM_PARTNER.rejectEng) {
        //     setIsDisabled(true);
        //     break;
        //   }
        // }
        setDataPartnerDetail(data?.data?.partners);
        const tmpArr2 = data?.data?.partners?.map((item) => {
          return item.partnerId;
        });
        setDataCheck(!_.isEmpty(tmpArr2) ? tmpArr2 : []);
        setDataPartnerCheck(tmpArr2);

        setDataPartnerConfirm(data?.data?.listConfirmStaff);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (dataPartner) {
      const partnerIdsList = dataPartner?.map(e => { return e.partnerId });
      const dataPartners = data?.partners || [];
      const dataPartnerConfirm = dataPartners?.filter(e => partnerIdsList.includes(e.partnerId));

      if (partnerIdsList?.length === dataPartnerConfirm?.length) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [data, dataPartner]);

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        erpMattersToBeSent: data?.erpMattersToBeSent,
        workerResponseRequired: data?.workerResponseRequired,
      });
      setDataOrder2({
        ...dataOrder,
        erpMattersToBeSent: data?.erpMattersToBeSent,
        workerResponseRequired: data?.workerResponseRequired,
      });
      setNamePartner(data?.partnerName);
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(dataPartner)) {
      let dataPartnerNew = _.map(dataPartner, function (obj) {
        return _.assign(
          obj,
          _.find(dataPartnerDetail, { partnerId: obj.partnerId }),
          _.find(dataPartnerConfirm, { partnerId: obj.partnerId })
        );
      });
      dataPartnerNew = _.sortBy(dataPartnerNew, ['statusOrderCondition']);
      setListPartnerAll(dataPartnerNew);
    }
  }, [dataPartnerDetail, dataPartner, dataPartnerConfirm]);

  useEffect(() => {
    const partnerIdsList = dataPartner?.map(e => { return e.partnerId });;
    const dataPartners = dataPartnerDetail || [];
    const dataPartnerConfirm = dataPartners.filter(e => partnerIdsList.includes(e.partnerId));
    if (!_.isEmpty(dataPartnerDetail) && !_.isEmpty(dataPartner) && dataPartnerConfirm?.length === dataPartner?.length) {
      setIsChecked(true);
    }
  }, [dataPartner, dataPartnerDetail]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    setDataOrder(newData);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    if (value === 'true') {
      newData = { ...newData, [name]: true };
    } else if (value === 'false') newData = { ...newData, [name]: false };
    setDataOrder(newData);
  };

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      const data = await ORDER.UPDATE_CONDITION_INFO({ body: body, id_order: id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        return history.push(`/list?p=condition_info`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateConfirmCondition = async (dataSubmit) => {
    try {
      const data = await ORDER.CONFIRM_CONDITION_INFO({ ...dataSubmit, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Confirm order success');
        return history.push(`/list?p=condition_info`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const resetAddPartner = async (dataSubmit) => {
    try {
      const data = await ORDER.RESET_ADD_PARTNER_ORDER({ ...dataSubmit, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Reset add partner order success');
        setDataPartnerDetail(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateOrder = () => {
    updateOrderInfo({ body: { ...dataOrder, companyName: localStorage.getItem('companyName'), updateTitle: '打診' }, id_order: orderId });
  };

  const confirmOrder = (partnerId, partnerName,updateTitle) => {
    const dataSummit = {
      id_order: orderId,
      screenName: SCREEN_NAME.CONDITION_INFO,
      statusConditionInfo: false,
      partnerId,
      partnerName,
      updateTitle,
      companyName: localStorage.getItem('companyName')
    };
    updateConfirmCondition(dataSummit);
  };

  const resetAddPartnerOrder = (partnerId,updateTitle) => {
    const dataSummit = {
      id_order: orderId,
      partnerId,
      updateTitle,
      companyName: localStorage.getItem('companyName')
    };
    resetAddPartner(dataSummit);
  };

  const handleCheckAll = (e) => {
    const target = e.target;
    if (target.checked) {
      const tmpPartner = listPartnerAll.map((item) => {
        return item.partnerId;
      });
      setDataPartnerCheck(tmpPartner);
      const newData = { ...dataOrder, partners: tmpPartner };
      setDataOrder(newData);
      setIsChecked(true);
    } else {
      setDataPartnerCheck([...dataCheck]);
      const newData = { ...dataOrder, partners: [...dataCheck] };
      setDataOrder(newData);
      setIsChecked(false);
    }
  };

  const handleChangeCheckItem = (e) => {
    const dataPartnerIdTmp = dataPartner.map(e => { return e.partnerId })
    let arrTmp = dataPartnerCheck?.filter(e => dataPartnerIdTmp.includes(e)) || [];
    let target = e.target;
    let value = target.value;
    if (target.checked) {
      arrTmp.push(value);
      if (arrTmp.length === dataPartner.length) {
        setIsChecked(true);
      }
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
      if (arrTmp.length !== dataPartner.length) {
        setIsChecked(false);
      }
    }
    setDataPartnerCheck(arrTmp);
    const newData = { ...dataOrder, partners: arrTmp };
    setDataOrder(newData);
  };

  const checkDisable = ({ partnerId, partnerOrganizationId, statusOrderCondition }) => {
    if (organizationId !== idProvider && data?.partnerConfirm?.[data?.partnerLogin] === partnerId) return true;
    if (idProvider !== partnerOrganizationId && data?.partnerConfirm?.[partnerId] == null ) return true;
     //if(statusOrderCondition === STATUS_CONFIRM_PARTNER.waitingEng) return true;

    return false;
  };

  return (
    <div className="wrapper-detail-condition-info">
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`${back === 'mypage' ? '/my_page' : `list?p=${p}`}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} key={index} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} key={index} />;
                case 'operationAdjustmentInfo':
                  return (
                    <div className="detail-condition-table-wrapper" key={index}>
                      <div className="title-absolute">稼働調整情報</div>
                      <div className="detail__condition">
                        <table className="detail__form">
                          <thead>
                            <tr>
                              <th>訪問日時</th>
                              <th>指定訪問日時</th>
                              <th>希望訪問日時①</th>
                              <th>希望訪問日時②</th>
                              <th>希望訪問日時③</th>
                              <th>希望訪問日時④</th>
                              <th>希望訪問日時⑤</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="detail__form--input text-center"
                                style={{ background: '#0e5a8d', color: 'white' }}
                              >
                                訪問日
                              </td>
                              <td className="detail__form--input">{data?.visitDate}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate1?.visitDate}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate2?.visitDate}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate3?.visitDate}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate4?.visitDate}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate5?.visitDate}</td>
                            </tr>
                            <tr>
                              <td
                                className="detail__form--input text-center"
                                style={{ background: '#0e5a8d', color: 'white' }}
                              >
                                開始予定
                              </td>
                              <td className="detail__form--input">{data?.visitDateFrom}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate1?.visitDateFrom}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate2?.visitDateFrom}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate3?.visitDateFrom}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate4?.visitDateFrom}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate5?.visitDateFrom}</td>
                            </tr>
                            <tr>
                              <td
                                className="detail__form--input text-center"
                                style={{ width: '10%', background: '#0e5a8d', color: 'white' }}
                              >
                                終了予定
                              </td>
                              <td className="detail__form--input">{data?.visitDateTo}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate1?.visitDateTo}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate2?.visitDateTo}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate3?.visitDateTo}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate4?.visitDateTo}</td>
                              <td className="detail__form--input">{data?.preferredDate?.visitDate5?.visitDateTo}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="detail__form">
                          <tbody>
                            <tr>
                              <th>作業員返答区分</th>
                              <td className="detail__form--radio">
                                <input
                                  onChange={handleChange}
                                  type="radio"
                                  value={true}
                                  name="workerResponseRequired"
                                  checked={dataOrder?.workerResponseRequired === true}
                                />
                                必要
                                <input
                                  onChange={handleChange}
                                  type="radio"
                                  value={false}
                                  name="workerResponseRequired"
                                  checked={dataOrder?.workerResponseRequired === false}
                                />
                                不要
                              </td>
                            </tr>
                            <tr>
                              <th>
                                {[5, 6, 7].includes(+idRole) ? 'ManagementGroup' : '契約企業'}<br />
                                申し送り事項
                              </th>
                              <td style={{ height: 100 }}>
                                <textarea
                                  value={dataOrder?.erpMattersToBeSent}
                                  onChange={handleChangeText}
                                  name="erpMattersToBeSent"
                                  cols="70"
                                  rows="5"
                                >
                                  {dataOrder?.erpMattersToBeSent}
                                </textarea>
                              </td>
                            </tr>
                            <tr>
                              <th>協力企業選択</th>
                              <td>
                                <div className="table__condition">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th style={{ width: 50, height: 30 }}>
                                          <div className="d-flex">
                                            <div>ALL</div>
                                            {
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  onChange={handleCheckAll}
                                                  checked={isChecked}
                                                  disabled={isDisabled}
                                                />
                                              </div>
                                            }
                                          </div>
                                        </th>
                                        <th>調整状況</th>
                                        <th>協力企業</th>
                                        <th>起点名</th>
                                        <th>距離</th>
                                        <th>対応エリア</th>
                                        <th>作業見積</th>
                                        <th>見積メモ</th>
                                        <th>見積ファイル</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {listPartnerAll &&
                                        listPartnerAll.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              {item?.statusOrderCondition === STATUS_CONFIRM_PARTNER.confirmedEng ||
                                              item?.statusOrderCondition === STATUS_CONFIRM_PARTNER.waitingEng ? (
                                                <td className="my__scrollbar--input">
                                                  <button
                                                    className="button-submit-condition__input btn-submit"
                                                    onClick={() => confirmOrder(item?.partnerId, item?.partnerName,'確定')}
                                                    disabled={checkDisable({ partnerId: item?.partnerId, partnerOrganizationId:item?.organizationId, statusOrderCondition:item?.statusOrderCondition})}

                                                  >

                                                    確保
                                                  </button>
                                                </td>
                                              ) : item?.statusOrderCondition === STATUS_CONFIRM_PARTNER.rejectEng ? (
                                                <td className="my__scrollbar--input">
                                                  <button
                                                    className="button-submit-condition__input "
                                                    onClick={() => resetAddPartnerOrder(item.partnerId,'再設定')}
                                                    //disabled={checkDisable({ partnerId: item?.partnerId, partnerOrganizationId:item?.organizationId, statusOrderCondition:item?.statusOrderCondition})}
                                                  >
                                                    再設定
                                                  </button>
                                                </td>
                                              ) : (
                                                <td className="my__scrollbar--input text-center text-info">
                                                  <input
                                                    type="checkbox"
                                                    name="partners"
                                                    disabled={isDisabled}
                                                    value={item.partnerId}
                                                    onChange={(e) => handleChangeCheckItem(e)}
                                                    checked={
                                                      dataPartnerCheck &&
                                                      dataPartnerCheck?.map((elm) => elm).includes(item.partnerId)
                                                    }
                                                  />
                                                </td>
                                              )}
                                              <td className="my__scrollbar--input">
                                                {item?.statusOrderCondition &&
                                                (data?.partnerConfirm?.[data?.partnerLogin] === item.partnerId && idProvider !== organizationId)
                                                  ? STATUS_CONFIRM_PARTNER.sendRequest
                                                  : item?.statusOrderCondition && (item?.statusOrderCondition === STATUS_CONFIRM_PARTNER.rejectEng)
                                                  ? STATUS_CONFIRM_PARTNER.rejectJP
                                                  : item?.statusOrderCondition &&
                                                  (
                                                    (item?.statusOrderCondition === STATUS_CONFIRM_PARTNER.confirmedEng &&
                                                    (!item?.isChildCompany || (item?.isChildCompany && item?.partnerId ===  data?.partnerLogin))) ||
                                                    (item?.isChildCompany && item?.partnerId !==  data?.partnerLogin && data?.partnerConfirm?.[item.partnerId])
                                                  )
                                                  ? STATUS_CONFIRM_PARTNER.confirmedJP
                                                  : item?.statusOrderCondition &&
                                                    (
                                                      (item?.statusOrderCondition === STATUS_CONFIRM_PARTNER.waitingEng &&
                                                        (!item?.isChildCompany || (item?.isChildCompany && item?.partnerId ===  data?.partnerLogin))) ||
                                                      (item?.isChildCompany && item?.partnerId !==  data?.partnerLogin && !data?.partnerConfirm?.[item.partnerId])
                                                    )
                                                  ? STATUS_CONFIRM_PARTNER.waitingJP
                                                  : dataPartnerCheck &&
                                                    !item?.statusOrderCondition &&
                                                    dataPartnerCheck?.map((elm) => elm).includes(item.partnerId)
                                                  ? STATUS_CONFIRM_PARTNER.checked
                                                  : STATUS_CONFIRM_PARTNER.empty}
                                              </td>
                                              <td className="my__scrollbar--input truncate" title={item?.partnerName}>
                                                {item?.abbreviationName}
                                              </td>
                                              <td className="my__scrollbar--input truncate"></td>
                                              <td className="my__scrollbar--input truncate" title={item?.distance}>
                                                {item?.distance}
                                              </td>
                                              <td className={item?.areaInOut ? 'my__scrollbar--input truncate' : 'my__scrollbar--input truncate text-danger'}>
                                                {item?.areaInOut ? `エリア内` : `エリア外`}</td>
                                              <td className="my__scrollbar--input" title={item?.estimate}>
                                                {item?.estimate}
                                              </td>
                                              <td className="my__scrollbar--input" title={item?.quoteMemo}>
                                                {item?.quoteMemo}
                                              </td>
                                              {item?.quoteFile?.url ? (
                                                <td
                                                  className="my__scrollbar--input cursor-pointer"
                                                  onClick={() => window.open(`${item?.quoteFile?.url}`)}
                                                >
                                                  {item?.quoteFile?.name}
                                                </td>
                                              ) : (
                                                <td className="my__scrollbar--input cursor-pointer">
                                                  {item?.quoteFile?.name}
                                                </td>
                                              )}
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                            {/* <tr>
                              <th className="input__custom--w25">パートナー名</th>
                              <td>
                                <div className="d-flex input-slelect">
                                  <input
                                    className="input__custom--w90"
                                    type="text"
                                    value={data?.partnerName}
                                    disabled
                                  />
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>

                      <div className="detail-data-wrapper">
                        <ButtonDetail text="保存" onUpdateOrder={onUpdateOrder} />
                      </div>
                    </div>
                  );
                case 'detailInfo':
                  return <DetailListAllData data={data} screenName={SCREEN_NAME.CONDITION_INFO} key={index} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={data} key={index} />;
                case 'history':
                  return (
                    <DetailDataList
                      datePick={false}
                      screenName={SCREEN_NAME.CONDITION_INFO}
                      orderId={orderId}
                      key={index}
                    />
                  );
                default:
                  return '';
              }
            }
          })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default compose(translate('translations'))(DetailConditionInfo);
